/*
 * CKEditor 5 (v24.0.0) content styles.
 * Generated on Thu, 10 Dec 2020 08:15:26 GMT.
 * For more information, check out https://ckeditor.com/docs/ckeditor5/latest/builds/guides/integration/content-styles.html
 */

:root {
  --ck-z-default: 999 !important;
  --ck-color-mention-background: hsla(341, 100%, 30%, 0.1);
  --ck-color-mention-text: hsl(341, 100%, 30%);
  --ck-highlight-marker-blue: hsl(201, 97%, 72%);
  --ck-highlight-marker-green: hsl(120, 93%, 68%);
  --ck-highlight-marker-pink: hsl(345, 96%, 73%);
  --ck-highlight-marker-yellow: hsl(60, 97%, 73%);
  --ck-highlight-pen-green: hsl(112, 100%, 27%);
  --ck-highlight-pen-red: hsl(0, 85%, 49%);
  --ck-image-style-spacing: 1.5em;
  --ck-todo-list-checkmark-size: 16px;
}

/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized {
  max-width: 100%;
  display: block;
  box-sizing: border-box;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized img {
  width: 100%;
}
/* ckeditor5-image/theme/imageresize.css */
.ck-content .image.image_resized > figcaption {
  display: block;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-side {
  float: right;
  margin-left: var(--ck-image-style-spacing);
  max-width: 50%;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-left {
  float: left;
  margin-right: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* ckeditor5-image/theme/imagestyle.css */
.ck-content .image-style-align-right {
  float: right;
  margin-left: var(--ck-image-style-spacing);
}
/* ckeditor5-image/theme/image.css */
.ck-content .image {
  display: table;
  clear: both;
  text-align: center;
  margin: 1em auto;
}
/* ckeditor5-image/theme/image.css */
.ck-content .image img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  min-width: 50px;
}
/* ckeditor5-image/theme/imagecaption.css */
.ck-content .image > figcaption {
  display: table-caption;
  caption-side: bottom;
  word-break: break-word;
  color: hsl(0, 0%, 20%);
  background-color: hsl(0, 0%, 97%);
  padding: 0.6em;
  font-size: 0.75em;
  outline-offset: -1px;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-yellow {
  background-color: var(--ck-highlight-marker-yellow);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-green {
  background-color: var(--ck-highlight-marker-green);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-pink {
  background-color: var(--ck-highlight-marker-pink);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .marker-blue {
  background-color: var(--ck-highlight-marker-blue);
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-red {
  color: var(--ck-highlight-pen-red);
  background-color: transparent;
}
/* ckeditor5-highlight/theme/highlight.css */
.ck-content .pen-green {
  color: var(--ck-highlight-pen-green);
  background-color: transparent;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-tiny {
  font-size: 0.7em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-small {
  font-size: 0.85em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-big {
  font-size: 1.4em;
}
/* ckeditor5-font/theme/fontsize.css */
.ck-content .text-huge {
  font-size: 1.8em;
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-block-quote/theme/blockquote.css */
.ck-content[dir='rtl'] blockquote {
  border-left: 0;
  border-right: solid 5px hsl(0, 0%, 80%);
}
/* ckeditor5-basic-styles/theme/code.css */
.ck-content code {
  background-color: hsla(0, 0%, 78%, 0.3);
  padding: 0.15em;
  border-radius: 2px;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table {
  margin: 1em auto;
  display: table;
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  border: 1px double hsl(0, 0%, 70%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table td,
.ck-content .table table th {
  min-width: 2em;
  padding: 0.4em;
  border: 1px solid hsl(0, 0%, 75%);
}
/* ckeditor5-table/theme/table.css */
.ck-content .table table th {
  font-weight: bold;
  background: hsla(0, 0%, 0%, 5%);
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir='rtl'] .table th {
  text-align: right;
}
/* ckeditor5-table/theme/table.css */
.ck-content[dir='ltr'] .table th {
  text-align: left;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break {
  position: relative;
  clear: both;
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break::after {
  content: '';
  position: absolute;
  border-bottom: 2px dashed hsl(0, 0%, 77%);
  width: 100%;
}
/* ckeditor5-page-break/theme/pagebreak.css */
.ck-content .page-break__label {
  position: relative;
  z-index: 1;
  padding: 0.3em 0.6em;
  display: block;
  text-transform: uppercase;
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  font-family: Helvetica, Arial, Tahoma, Verdana, Sans-Serif;
  font-size: 0.75em;
  font-weight: bold;
  color: hsl(0, 0%, 20%);
  background: hsl(0, 0%, 100%);
  box-shadow: 2px 2px 1px hsla(0, 0%, 0%, 0.15);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* ckeditor5-media-embed/theme/mediaembed.css */
.ck-content .media {
  clear: both;
  margin: 1em 0;
  display: block;
  min-width: 15em;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list {
  list-style: none;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li {
  margin-bottom: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list li .todo-list {
  margin-top: 5px;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input {
  -webkit-appearance: none;
  display: inline-block;
  position: relative;
  width: var(--ck-todo-list-checkmark-size);
  height: var(--ck-todo-list-checkmark-size);
  vertical-align: middle;
  border: 0;
  left: -25px;
  margin-right: -15px;
  right: 0;
  margin-left: 0;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::before {
  display: block;
  position: absolute;
  box-sizing: border-box;
  content: '';
  width: 100%;
  height: 100%;
  border: 1px solid hsl(0, 0%, 20%);
  border-radius: 2px;
  transition: 250ms ease-in-out box-shadow, 250ms ease-in-out background,
    250ms ease-in-out border;
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input::after {
  display: block;
  position: absolute;
  box-sizing: content-box;
  pointer-events: none;
  content: '';
  left: calc(var(--ck-todo-list-checkmark-size) / 3);
  top: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  width: calc(var(--ck-todo-list-checkmark-size) / 5.3);
  height: calc(var(--ck-todo-list-checkmark-size) / 2.6);
  border-style: solid;
  border-color: transparent;
  border-width: 0 calc(var(--ck-todo-list-checkmark-size) / 8)
    calc(var(--ck-todo-list-checkmark-size) / 8) 0;
  transform: rotate(45deg);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::before {
  background: hsl(126, 64%, 41%);
  border-color: hsl(126, 64%, 41%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label > input[checked]::after {
  border-color: hsl(0, 0%, 100%);
}
/* ckeditor5-list/theme/todolist.css */
.ck-content .todo-list .todo-list__label .todo-list__label__description {
  vertical-align: middle;
}
/* ckeditor5-html-embed/theme/htmlembed.css */
.ck-content .raw-html-embed {
  margin: 1em auto;
  min-width: 15em;
  font-style: normal;
}
/* ckeditor5-horizontal-line/theme/horizontalline.css */
.ck-content hr {
  margin: 15px 0;
  height: 4px;
  background: hsl(0, 0%, 87%);
  border: 0;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre {
  padding: 1em;
  color: hsl(0, 0%, 20.8%);
  background: hsla(0, 0%, 78%, 0.3);
  border: 1px solid hsl(0, 0%, 77%);
  border-radius: 2px;
  text-align: left;
  direction: ltr;
  tab-size: 4;
  white-space: pre-wrap;
  font-style: normal;
  min-width: 200px;
}
/* ckeditor5-code-block/theme/codeblock.css */
.ck-content pre code {
  background: unset;
  padding: 0;
  border-radius: 0;
}
/* ckeditor5-mention/theme/mention.css */
.ck-content .mention {
  background: var(--ck-color-mention-background);
  color: var(--ck-color-mention-text);
}
@media print {
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break {
    padding: 0;
  }
  /* ckeditor5-page-break/theme/pagebreak.css */
  .ck-content .page-break::after {
    display: none;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0px;
  font-family: AvantGarde-Bk;
}

a {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

.CircularProgressbar {
  width: 70% !important;
}
.CircularProgressbar .CircularProgressbar-path {
  stroke: #01caff !important;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s ease 0s;
}
.CircularProgressbar .CircularProgressbar-trail {
  stroke: #e6e6e6 !important;
  stroke-linecap: round;
}
.exchange-icon {
  color: #01caff;
  font-size: 60px;
  cursor: pointer;
}

.opacity-inverse {
  opacity: 0.5;
}

.opacity:hover {
  opacity: 0.4;
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.pagination {
  display: inline-block;
}

.calendar {
  color: black;
  font-size: 22px;
  cursor: pointer;
  margin-right: 15px;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 10px;
  text-decoration: none;
  font-family: 'AvantGarde-Bk';
  font-size: 12px;
}
.chevron-right {
  font-size: 26px;
  cursor: pointer;
}
.star-star {
  font-size: 18px;
  cursor: pointer;
  text-align: center;
}
.box-buttom {
  position: absolute;
  right: 20%;
  /* z-index: 999;
  top: -30px;
  right: 80%; */
}
.total-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.total-center-match-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons-total-center {
  padding: 1rem 0 2rem 0;
}
.row-cont {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row-cont-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col-cont {
  display: flex;
  flex-direction: column;
}
.flex-fill {
  flex-basis: 0;
  flex-grow: 1;
}
.flex-end-row {
  justify-content: flex-end;
}
.flex-end-col {
  align-items: flex-end;
}
.space-between {
  justify-content: space-between;
}

.down-arrow {
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  margin-left: 5px;
}

.align-items-start {
  align-items: flex-start;
}

.text-bold {
  font-family: 'AvantGarde-Bold';
}

.text-demi-bold {
  font-family: 'AvantGarde-Demi';
}

.text-color-accent {
  color: #01caff;
}

.direction-column {
  flex-direction: column;
}
.direction-column2 {
  flex-direction: column;
  align-self: center;
  height: 200px;
}

.slick-next {
  position: absolute;
  z-index: 10;
  height: 30px;
  border-radius: 25px;
  opacity: 0.5;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  right: -15px;
}

.slick-prev {
  position: absolute;
  left: -15px;
  z-index: 10;
  height: 30px;
  border-radius: 25px;
  opacity: 0.5;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.slick-next:hover {
  opacity: 1;
}

.slick-prev:hover {
  opacity: 1;
}

.paddiing {
  width: calc(100% - 20px);
  padding: 0px 10px;
}

.Toastify__toast-container {
  z-index: 9999999999 !important;
}

.custom-slider .slick-dots {
  bottom: -21px;
}
.sliderCenterDots .slick-dots {
  bottom: -1px;
}

.slick-dots .slick-active button {
  opacity: 1;
  color: #000;
}

.react-tagsinput {
  border-bottom: solid 4px #c0c0c0 !important;
  border-left: none !important;
  border-right: none !important;
  background-color: #eff0ed !important;
}

.react-tags-input {
  min-width: 200px;
  background-color: transparent;
  border: none;
  margin-bottom: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto';
  padding-left: 8px;
  font-size: 16px;
  color: #000;
}
.react-tags-input:focus {
  outline: none;
}

.input-error-text {
  color: red;
  font-size: 12px;
}

.react-calendar {
  z-index: 1;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  background: #01caff;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 0px !important;
  background: #01caff;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  color: white;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: white;
}
.react-tagsinput--focused {
  border-top-color: #c0c0c0 !important;
  border-bottom: solid 4px #b6fe17 !important;
}
.arrowDropDown {
  position: absolute;
  top: -17px;
  color: #01caffdf;
  font-size: 30px;
}
.settings_content {
  position: relative;
  text-align: center;
  width: 100%;
  margin: 0% auto;
}
.settings_content .settings_content_wrapper {
  position: relative;
  display: flex;
  box-shadow: 0 0 5px 1px rgb(255, 255, 255);
  justify-content: space-evenly;
  border-radius: 8px;
  background: #ffffff;
  flex-wrap: wrap;
}
.settings_content_wrapper > div {
  margin: 1%;
}
.roes_editor {
  box-shadow: 0 0 5px 1px lightgray;
  background: white;
  min-height: 500px;
  width: 100%;
  margin: 1.5% 0% 3% 0%;
  padding: 2%;
  overflow: auto;
  border-radius: 20px;
  outline: none;
}
.roes_editor ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
.roes_editor ul li {
  list-style: disc;
}
.btn-options-content {
  display: inline-block;
  appearance: none;
  height: 100%;
  border: 0px solid;
  width: 100%;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.775rem 1.55rem;
  font-size: 15px;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  background: #07386691;
  color: white;
  text-shadow: 0 0 1px;
}
.homecontainer {
  padding: 0.5% 13%;
  background-image: url('./images/home-background.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.bglinearcontainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(180deg, #000000b7, #ceff41c9);
  z-index: 5;
}
/* @font-face {
  font-family: 'AvantGarde-Bk';
  src: url('./fonts/centurygothic-webfont.woff') format('truetype');
}

@font-face {
  font-family: 'Futura';
  src: url('./fonts/futura-pt-heavy.woff2') format('truetype');
}

@font-face {
  font-family: 'AvantGarde-Bold';
  src: url('./fonts/hinted-CenturyGothic-Bold.woff2') format('truetype');
} */

@font-face {
  font-family: 'AvantGarde-Bk';
  src: url('./fonts/ITCAvantGardeStd-Bk.otf') format('truetype');
}

@font-face {
  font-family: 'AvantGarde-Bold';
  src: url('./fonts/ITCAvantGardeStd-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'AvantGarde-Demi';
  src: url('./fonts/ITCAvantGardeStd-Demi.otf') format('truetype');
}
@font-face {
  font-family: 'AvantGarde-Md';
  src: url('./fonts/ITCAvantGardeStd-Md.otf') format('truetype');
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div {
  outline: none;
}

/* .header-arrow-down:hover {
  animation-name: down-arrow-header;
  animation-fill-mode: backwards;
  animation-duration: 0.7s;
} */

/* @keyframes down-arrow-header {
  from {
    margin-top: 0px;
  }
  to {
    margin-top: 15px;
  }
} */

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.notice-card-image {
  height: 450px;
}

.notice-card {
  margin: 20px;
}

.material-ui-input {
  background: #eff0ed;
  height: 30px;
  width: calc(100% - 20px);
  padding: 0px 10px;
  font-size: 14px !important;
  color: #555 !important;
  border: none;
  border-bottom: 3px solid #bfbfbe;
}

[class^='MuiFormControl-'] {
  width: 100% !important;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar:horizontal {
  height: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.responsive-img {
  width: 100%;
  max-width: 50px;
  height: auto;
}

.hide-autofill-control:-webkit-autofill {
  -webkit-transform: translateY(-1.28125em) scale(0.75) perspective(100px)
    translateZ(0.00101px);
  transform: translateY(-1.28125em) scale(0.75) perspective(100px)
    translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.33334333%;
}

.wrapper-editor-class {
  background-color: #eaeaea;
  border-bottom: solid 3px #c0c0c0;
  min-height: 300px;
}

.editor-class {
  padding-left: 10px;
  padding-right: 10px;
}

.linearBlackGr {
  position: absolute;
  height: 90px;
  width: 100%;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
}

.media iframe {
  border: 0;
}

.justify-content-between {
  justify-content: space-between;
}

.container-item-match-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
}
.container-item-match-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 150px;
}

.name-team-item-match,
.vs-item-match {
  margin-top: 10px;
  padding: 10px;
  color: #000;
  overflow: hidden;
  white-space: break-spaces;
}

.MatchesInfoPlayers {
  display: flex;
  gap: 0.7em;
}

.react-tagsinput span {
  display: flex;
  flex-wrap: wrap;
}

.team-email {
  background-color: #CEFF41 !important;
}

.non-team-email {
  background-color: #01CAFF !important;
}

.react-tagsinput-remove {
  margin-left: 0.3em;
}

@media (max-width: 420px) {
  .hidden-xs {
    display: none !important;
  }
  .homecontainer {
    padding: 0%;
  }
}

@media (max-width: 420px) {
  .show-for-mobile-select {
    padding: 0%;
    color: beige;
  }

  .container-item-match-left {
    width: 130px;
  }

  .container-item-match-right {
    width: 130px;
  }

  .name-team-item-match {
    padding: 0;
  }
}

@media (min-width: 420px) {
  .hidden-xs-and-up {
    display: none !important;
  }
}

@media (max-width: 768px) {
  .hidden-sm {
    display: none !important;
  }
  .homecontainer {
    padding: 0% !important;
    overflow: hidden;
  }
  .sliderCenterDots .slick-dots {
    bottom: -30px;
  }
}

@media (min-width: 768px) {
  .hidden-md-and-up {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .hidden-md {
    display: none !important;
  }
  .homecontainer {
    padding: 0.5% 5%;
  }
}

@media (min-width: 992px) {
  .hidden-lg-and-up {
    display: none !important;
  }
}

@media (min-width: 1100px) {
  .homecontainer {
    padding: 0.5rem 4rem;
  }
}

@media (min-width: 1200px) {
  .homecontainer {
    padding: 0.5rem 6rem;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.slick-next:before,
.slick-prev:before {
  color: rgb(0, 0, 0);
}

.overflow-x-hidden {
  overflow-x: hidden;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
  select,
  textarea,
  input[type='text'],
  input[type='password'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='date'],
  input[type='month'],
  input[type='time'],
  input[type='week'],
  input[type='number'],
  input[type='email'],
  input[type='tel'],
  input[type='url'] {
    font-size: 16px !important;
  }
}

.width-mobile {
  flex: 1;
}